<template>
    <div class="rounded-md px-6 py-4 mb-5 text-center" :class="classes[message.type] ?? ''" v-html="message.content">
    </div>
</template>

<script setup>
    defineProps({
        message: Object
    });

    const classes = {
        'error' : 'bg-red-100 text-red-800',
        'warning' : 'bg-orange-100 text-orange-800',
        'success' : 'bg-green-100 text-green-800'
    };
</script>